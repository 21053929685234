<template>
  <div class="social-icons">
    <a
      v-if="tiktok"
      href="https://tiktok.com/@candlescience"
      class="social-link"
      aria-label="Visit CandleScience on TikTok"
    >
      <SvgIcon icon="tiktok" />
    </a>
    <a
      v-if="instagram"
      href="https://www.instagram.com/candlescience"
      class="social-link"
      aria-label="Visit CandleScience on Instagram"
    >
      <SvgIcon icon="instagram" />
    </a>
    <a
      v-if="youtube"
      href="https://www.youtube.com/channel/UCmaglnFeg6S_Qh1IfrIuPuA"
      class="social-link"
      aria-label="Visit CandleScience on Youtube"
    >
      <SvgIcon icon="youtube" />
    </a>
    <a
      v-if="facebook"
      :href="
        sharing
          ? `https://facebook.com/sharer/sharer.php?u=${fullUrl}`
          : 'https://www.facebook.com/candlescience/'
      "
      class="social-link"
      target="_blank"
      aria-label="Visit CandleScience on Facebook"
    >
      <SvgIcon icon="facebook" />
    </a>
    <a
      v-if="pinterest"
      :href="
        sharing
          ? `https://pinterest.com/pin/create/button/?url=${fullUrl}`
          : 'https://www.pinterest.com/candlescience/'
      "
      class="social-link"
      target="'_blank'"
      aria-label="Visit CandleScience on Pinterest"
    >
      <SvgIcon icon="pinterest" />
    </a>
  </div>
</template>

<script setup lang="ts">
  const { $config } = useNuxtApp()
  const route = useRoute()

  defineProps({
    sharing: {
      type: Boolean,
      default: false,
    },
    youtube: {
      type: Boolean,
      default: false,
    },
    instagram: {
      type: Boolean,
      default: false,
    },
    facebook: {
      type: Boolean,
      default: false,
    },
    tiktok: {
      type: Boolean,
      default: false,
    },
    pinterest: {
      type: Boolean,
      default: false,
    },
  })

  const fullUrl = computed(() => {
    return $config.public.BASE_URL + route.fullPath
  })
</script>

<style lang="scss" scoped>
  .social-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .social-link {
    padding: $base-spacing * 1.5;
    display: flex;
    align-items: center;

    svg {
      transition: stroke 120ms ease-out;
      width: $base-spacing * 6;
      height: $base-spacing * 6;
      color: $gray-shape;
    }

    &:hover svg {
      color: $gray-800;
    }
  }
</style>
